import Request from '@/utils/request';

//审批选项
export function getApproveOpt(data) {
  return Request('post', '/apg/oa/approve/process/processPage', data)
}

//审批流程
export function getApproveFlow(data) {
  return Request('post', '/apd/oa/approve/startTest', data)
}

//获取人员列表
export function getEmpList(data) {
  return Request('post', '/apg/oa/approve/process/findEmpList', data)
}


//提交审批
export function getApproveSubmit(data) {
  return Request('post', '/apd/oa/approve/start', data)
}