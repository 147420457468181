<template>
  <div id="addContractNew">
    <van-sticky>
      <Back title="合同签约"></Back>
    </van-sticky>

    <!-- -----------------------------客户信息------------------------ -->
    <div class="userInfo box-item">
      <p class="pTitle">客户信息</p>
      <van-cell-group>
        <van-field label="客户" v-model="tenantName" readonly />
        <van-field
          required
          label="企业名称"
          v-model="companyName"
          placeholder="请输入"
        />
        <van-field label="联系人" v-model="signUserName" readonly />
        <van-field label="联系电话" v-model="signUserMobile" readonly />
        <van-field label="行业" v-model="industryName" readonly />
        <van-field label="法人" v-model="legalPerson" placeholder="请输入" />
        <!-- 部门~ -->
        <van-field
          is-link
          required
          readonly
          clickable
          label="部门"
          placeholder="请选择部门"
          @click="deptShowEven()"
          v-model="deptName"
        />
        <!-- 客户经理@html -->
        <van-field
          is-link
          required
          readonly
          clickable
          label="客户经理"
          placeholder="请选择客户经理"
          @click="customerShowEven()"
          v-model="customerManagerIdName"
        />
      </van-cell-group>
    </div>
    <!-- ----------------------------房源信息 --------------------------->
    <div class="roomInfo box-item">
      <p class="pTitle">房源信息</p>
      <!-- 园区@html -->
      <van-field
        is-link
        required
        readonly
        clickable
        label="园区"
        placeholder="请选择园区"
        @click="projectShowEven()"
        v-model="projectName"
      />
      <!-- 房源类型 -->
      <van-field
        is-link
        required
        readonly
        clickable
        label="房源类型"
        placeholder="请选择房源类型"
        @click="resourceTypeShowEven()"
        v-model="resourceTypeName"
      />
      <van-field
        is-link
        required
        readonly
        clickable
        label="楼幢房号"
        placeholder="请选择楼幢房号"
        @click="resourceShowEven()"
      />
      <!-- 所选房源展示 -->
      <template v-if="resourceResult.length > 0">
        <van-cell
          class="r1"
          readonly
          v-for="item in resourceResult"
          :key="item.resourceId"
          :title="`${item.unitName}` + ` ` + `${item.resourceName}`"
          style="background: #eee"
        >
          <span>{{ item.area }}㎡</span>
          <span v-if="item.fitmentCategory === 1">豪华装修</span>
          <span v-else-if="item.fitmentCategory === 2">精装修</span>
          <span v-else-if="item.fitmentCategory === 3">简装</span>
          <span v-else-if="item.fitmentCategory === 4">标准交付</span>
          <span v-else-if="item.fitmentCategory === 5">毛胚</span>
          <span v-else>{{ item.fitmentCategory }}</span>
          <template slot="extra">
            <van-icon
              size="20"
              @click="closeresource(item)"
              name="close" /></template
        ></van-cell>
      </template>
    </div>
    <!-- ----------------------------基础条款 ---------------------------->
    <div class="beseInfo box-item">
      <p class="pTitle">基础条款</p>
      <van-field label="租赁数量" v-model="quantity" readonly>
        <template slot="extra">
          <div>平米</div>
        </template>
      </van-field>
      <van-field label="合同签订日期" v-model="dateFilter" readonly />
      <!-- 合同开始日期 -->
      <van-field
        is-link
        required
        readonly
        clickable
        label="合同开始时间"
        placeholder="请选择开始日期"
        @click="effDateShow = true"
        :value="effDateFilter"
      />
      <!-- 合同结束日期 -->
      <van-field
        is-link
        required
        readonly
        clickable
        label="合同结束时间"
        placeholder="请选择结束日期"
        @click="expDateShow = true"
        :value="expDateFilter"
      />
      <!--租房押金  -->
      <van-field
        required
        label="租房押金"
        v-model="depositUnitPrice"
        placeholder="请输入"
        maxlength="10"
        type="number"
      >
        <template slot="extra">
          <van-radio-group v-model="depositUnit" direction="horizontal">
            <van-radio :name="1" shape="square">月</van-radio>
            <van-radio :name="2" shape="square">元</van-radio>
          </van-radio-group>
        </template></van-field
      >
      <!-- 物业押金 -->
      <van-field
        required
        label="物业押金"
        v-model="propertyDepositUnitPrice"
        placeholder="请输入"
        maxlength="10"
        type="number"
        ><template slot="extra">
          <van-radio-group v-model="propertyDepositUnit" direction="horizontal">
            <van-radio :name="1" shape="square">月</van-radio>
            <van-radio :name="2" shape="square">元</van-radio>
          </van-radio-group>
        </template></van-field
      >
    </div>
    <!-- ----------------------------租赁条款 ---------------------------->
    <div class="leaseInfo box-item">
      <p class="pTitle">租赁条款</p>
      <!-- 租赁条款计费周期 -->
      <van-field
        label="计费周期"
        v-model="zuqiList.payCycle"
        type="number"
        maxlength="2"
        required
        @input="changePayCycle"
      >
        <template slot="extra">月一付</template></van-field
      >
      <!-- 自定义计费周期 -->
      <van-field label="自定义周期" v-model="diyPayCycle" readonly>
        <template slot="button">
          <van-button size="small" type="info" @click="cycleEdit()"
            >编辑</van-button
          >
        </template>
      </van-field>
      <!-- 租赁条款提前还款时间 -->
      <van-field
        label="提前还款时间"
        v-model="zuqiList.advancePaytime"
        type="number"
        maxlength="2"
        readonly
      >
        <template slot="extra">自然日</template></van-field
      >
      <!-- 租赁条款计费类型 -->
      <van-field label="计费类型" readonly
        ><template slot="extra" style="flex: 1">
          <van-radio-group
            v-model="zuqiList.calcTimeUnit"
            direction="horizontal"
          >
            <van-radio :name="1" shape="square">天</van-radio>
            <van-radio :name="2" shape="square">月</van-radio>
          </van-radio-group>
        </template></van-field
      >
      <!-- 租赁条款基础单价 -->
      <van-field
        required
        label="基础单价"
        v-model="zuqiList.unitPrice"
        placeholder="请输入"
        type="number"
        maxlength="10"
        ><template slot="extra">
          <van-radio-group
            v-model="zuqiList.pricingUnit"
            direction="horizontal"
          >
            <van-radio :name="6" shape="square">元/月</van-radio>
            <van-radio :name="1" shape="square">元/㎡/天</van-radio>
          </van-radio-group>
        </template></van-field
      >
      <!-- -----------------------租赁条款租金递增率----------------->
      <van-field label="租金递增率" readonly>
        <template slot="extra">
          <van-checkbox v-model="dizengChecked"></van-checkbox></template
      ></van-field>
      <!-- 租赁条款租金递增--下 -->
      <div class="inside-box" v-show="dizengChecked">
        <van-field
          required
          label="租金递增率"
          v-model="dizengList.increment"
          placeholder="请输入"
          style="background: #eee"
          type="number"
          maxlength="10"
          ><template slot="extra">
            <van-radio-group
              v-model="dizengList.incrementUnit"
              direction="horizontal"
            >
              <van-radio :name="1" shape="square">%</van-radio>
              <van-radio :name="2" shape="square">元</van-radio>
            </van-radio-group>
          </template></van-field
        >
        <van-cell
          is-link
          readonly
          title="请选择递增时间点"
          @click="dizengShow = true"
          style="background: #eee; border-bottom: 10px #fff solid"
        />
        <!-- 递增时间展示 -->
        <van-cell
          v-for="(item, index) in contractIncreaseVoList"
          :key="index"
          :title="item.increaseDate"
        >
          <div class="dizengText">
            <span>递增率</span> {{ item.increment }}
            <span v-if="item.incrementUnit === 1">%</span><span v-else>元</span>
          </div>
          <template slot="extra">
            <van-icon
              size="20"
              @click="closedizeng(item, index)"
              name="close" /></template
        ></van-cell>
      </div>
      <!-- -------------------------租赁条款免租期------------------->
      <van-field label="租金免租期" readonly>
        <template slot="extra">
          <van-checkbox v-model="mianzuChecked"></van-checkbox></template
      ></van-field>
      <!-- 租赁条款免租期-下 -->
      <div class="inside-box" v-show="mianzuChecked">
        <van-cell
          is-link
          readonly
          title="请选择日期区间"
          @click="mianzuShow = true"
          style="background: #eee; border-bottom: 10px #fff solid"
        />
        <!-- 免租期展示 -->
        <van-cell
          v-for="(item, index) in contractDiscountVoList"
          :key="index"
          :title="`${item.startDate}~${item.endDate}`"
        >
          <template slot="extra">
            <van-icon
              size="20"
              @click="closemianzu(item, index)"
              name="close" /></template
        ></van-cell>
      </div>
    </div>
    <!-- ----------------------------物业费条款 ---------------------------->
    <div class="wuyeInfo box-item">
      <p class="pTitle">物业费条款</p>
      <!-- 物业费条款计费类型 -->
      <van-field label="计费类型" readonly
        ><template slot="extra">
          <van-radio-group
            v-model="wuyeList.calcTimeUnit"
            direction="horizontal"
          >
            <!-- <van-radio :name="1" shape="square">天</van-radio> -->
            <van-radio :name="2" shape="square">月</van-radio>
          </van-radio-group>
        </template></van-field
      >
      <!-- 物业费条款基础单价 -->
      <van-field
        required
        label="基础单价"
        v-model="wuyeList.unitPrice"
        placeholder="请输入"
        type="number"
        maxlength="10"
        ><template slot="extra">
          <van-radio-group
            v-model="wuyeList.pricingUnit"
            direction="horizontal"
          >
            <van-radio :name="6" shape="square">元/月</van-radio>
            <van-radio :name="2" shape="square">元/㎡/月</van-radio>
          </van-radio-group>
        </template></van-field
      >
    </div>
    <!-- 生成费用明细 -->
    <div class="box-item">
      <van-cell
        is-link
        value="生成费用明细"
        @click="getnewCalculateFee"
        style="padding-left: 0"
        ><span class="pTitle">费用明细</span></van-cell
      >
    </div>
    <!-- 备注 -->
    <div class="box-item">
      <p class="pTitle">备注</p>
      <van-field label="备注信息" v-model="remark" placeholder="请输入" />
    </div>
    <!-- 提交 -->
    <div style="margin: 16px">
      <van-button round block type="info" @click="saveData()"
        >保存并审批</van-button
      >
    </div>

    <!-- ===================================弹框区域==============================-->
    <!-- 加载 -->
    <van-overlay :show="loadingShow">
      <div class="wrapper">
        <van-loading color="#1989fa">加载中...</van-loading>
      </div>
    </van-overlay>
    <!-- -----------------------------客户信息------------------------ -->
    <!-- 部门弹框 -->
    <van-popup v-model="deptShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="deptList"
        value-key="deptName"
        @cancel="deptShow = false"
        @confirm="deptConfirm"
      />
    </van-popup>
    <!-- 客户经理@弹框 -->
    <van-popup v-model="customerShow" round position="bottom">
      <van-picker
        show-toolbar
        value-key="empName"
        :columns="customerList"
        @cancel="customertShow = false"
        @confirm="customerConfirm"
      />
    </van-popup>
    <!-- ----------------------------房源信息 --------------------------->
    <!-- 园区@弹框 -->
    <van-popup v-model="projectShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="projectList"
        value-key="projectName"
        @cancel="projectShow = false"
        @confirm="projectConfirm"
      />
    </van-popup>
    <!-- 房源类型 -->
    <van-popup v-model="resourceTypeShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="resourceTypeList"
        value-key="resourceTypeName"
        @cancel="resourceTypeShow = false"
        @confirm="resourceTypeConfirm"
      />
    </van-popup>
    <!-- 楼幢房号 -->
    <van-popup
      v-model="resourceShow"
      position="right"
      :style="{ width: '60%', height: '100%' }"
      class="s0"
    >
      <section class="s1">房源列表</section>
      <section class="s4">
        <van-search
          v-model="searchValue"
          placeholder="请输入关键词"
          @input="onSearch()"
        >
        </van-search>
      </section>
      <section class="s2">
        <van-checkbox-group
          v-if="resourcelistFilter.length > 0"
          v-model="resourceResult"
        >
          <van-cell-group>
            <van-cell
              clickable
              v-for="item in resourcelistFilter"
              :key="item.resourceId"
              :title="`${item.unitName}` + ` ` + `${item.resourceName}`"
            >
              <template #right-icon>
                <van-checkbox :name="item" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <p v-else class="s2-1">暂无可选房源</p>
      </section>
      <section class="s3">
        <van-button type="info" @click="resourceShow = false">确 认</van-button>
      </section>
    </van-popup>
    <!-- ----------------------------基础信息 --------------------------->
    <!-- 合同开始日期@弹框 -->
    <van-popup v-model="effDateShow" round position="bottom">
      <van-datetime-picker
        v-model="effDate"
        type="date"
        title="合同开始时间"
        :min-date="ht_startDate"
        @cancel="effDateShow = false"
        @confirm="effDateShow = false"
        @change="changePayCycle"
      />
    </van-popup>
    <!-- 合同结束日期@弹框 -->
    <van-popup v-model="expDateShow" round position="bottom">
      <van-datetime-picker
        v-model="expDate"
        type="date"
        title="合同结束时间"
        :min-date="ht_endDate"
        @cancel="expDateShow = false"
        @confirm="expDateShow = false"
        @change="changePayCycle"
      />
    </van-popup>
    <!-- 合同免租期区间@弹框 -->
    <van-calendar
      v-model="mianzuShow"
      type="range"
      @confirm="mianzuConfirm"
      :min-date="minDate"
      :max-date="maxDate"
      color="#1989fa"
    />
    <van-popup v-model="dizengShow" round position="bottom">
      <van-datetime-picker
        type="date"
        title="租金递增时间"
        :min-date="dizengMinDate"
        :max-date="maxDate"
        @cancel="dizengShow = false"
        @confirm="dizengDateConfirm"
      />
    </van-popup>
    <!-- ----------------------------费用明细 --------------------------->
    <van-popup
      v-model="detailsShow"
      position="right"
      :style="{ width: '100%', height: '100%' }"
      class="s0"
    >
      <section class="costinfo">
        <span @click="detailsShow = false"
          ><van-icon name="arrow-left" />费用明细表</span
        >
      </section>
      <section class="s2">
        <expense-details :costInfoData="costInfoData"></expense-details>
      </section>
    </van-popup>
    <!-- ----------------------------审批流程 --------------------------->
    <van-popup
      v-model="approvalShow"
      position="right"
      :style="{ width: '100%', height: '100%' }"
      class="s0"
    >
      <section class="s10">
        <Approve
          processType="1"
          @success="onCloseSon"
          :businessId="businessId"
        ></Approve>
      </section>
    </van-popup>
    <!-- ----------------------------自定义周期 --------------------------->
    <van-popup
      v-model="cycleShow"
      position="right"
      :style="{ width: '100%', height: '100%' }"
      class="s0"
    >
      <section class="s1">付款时间轴</section>
      <van-steps
        class="payCycleTime"
        direction="vertical"
        active-color="#969799"
      >
        <div
          v-for="(item, index) in diyPayCycleArr"
          :key="index"
          @click="editCycleDate(index)"
          class="activeWrap"
        >
          <van-step
            v-if="
              new Date(item.endDate).getTime() <
              new Date(cycleEndDate).getTime()
            "
          >
            <h3>{{ item.endDate }}</h3>
            <p v-if="index === 0">开始日期</p>
            <p v-if="index > 0 && index < diyPayCycleArr.length - 1">
              第{{ index }}期
            </p>
          </van-step>
        </div>
      </van-steps>
      <van-steps
        class="payCycleTime"
        direction="vertical"
        active-color="#969799"
      >
        <van-step>
          <h3>{{ cycleEndDate }}</h3>
          <p>结束日期</p>
        </van-step>
      </van-steps>

      <van-button class="diyPayCycleBtn" type="info" @click="returnMenu()"
        >返 回</van-button
      >
    </van-popup>
    <!-- 自定义周期弹框  -->
    <van-calendar
      v-model="cycleDateShow"
      @confirm="cycleDateConfirm"
      color="#1989fa"
      :min-date="cycleMinDate"
      :max-date="cycleMaxDate"
    />
  </div>
</template>

<script>
import Back from "@/components/back";
import ExpenseDetails from "./child/expenseDetails";
import Approve from "@/components/approve";

import {
  consultantUserList,
  getProject,
  projectSelect,
  newCalculateFee,
  newContractEdit,
  newSave,
  newSave2,
  getDiyPayCycle,
  detail,
  clueEdit,
} from "@/api/clue/clue.js";
import { Toast } from "vant";
export default {
  components: { Back, ExpenseDetails, Approve },
  data() {
    return {
      title: "新增租赁合同",
      show: [true, false],
      loadingShow: false,
      detailsShow: false, //费用明细
      approvalShow: false, //审批
      contractFeeInfoBoList: [], //保存明细
      costInfoData: [], //费用明细手机端展示数据
      submitData: null,
      businessId: null,
      // --------------------------------基础信息-----------------------
      token: "", //令牌
      clueId: null, //线索id
      contractId: null,
      contractAccess: null, //合同类型
      contractType: null, //合同类型
      calcPrecision: "1", //计算精度
      unitPricePrecision: "2", //保留小数位
      remark: "", //备注

      // --------------------------------客户信息------------------------
      tenantId: null, //客户id
      tenantName: "", //客户名
      signUserId: null, //联系人id
      signUserName: "", //联系人名
      signUserMobile: null, //联系电话
      industryId: null, //行业id
      industryName: "", //行业名
      legalPerson: "", //法人
      contractCode: null, //合同编码
      companyName: "", // 企业名称
      // 部门~
      deptId: null, //部门id
      deptName: "", //部门名
      deptShow: false, //部门弹窗开关
      deptList: [], //部门列表
      //客户经理~
      customerManagerId: null, //客户经理id
      customerManagerIdName: "", //客户经理名
      customerShow: false, //客户经理弹窗开关
      customerList: [], //客户经理列表

      // --------------------------------房源信息------------------------
      //园区@data
      projectId: null, //园区id
      projectName: "", //园区名
      projectShow: false, //园区弹窗开关
      projectList: [], //园区列表

      //房源类型
      resourceType: 1, //房源类型id
      resourceTypeName: "办公室", //房源类型名
      resourceTypeShow: false, //房源类型弹窗开关
      resourceTypeList: [
        {
          resourceType: 1,
          resourceTypeName: "办公室",
        },
        {
          resourceType: 2,
          resourceTypeName: "工位间",
        },
        {
          resourceType: 3,
          resourceTypeName: "公寓",
        },
        {
          resourceType: 2,
          resourceTypeName: "宿舍间",
        },
      ], //房源类型列表
      searchValue: "",
      // 所有房源列表
      resourcelist: [],
      //搜索结果
      resourceArr: [],
      //楼幢房号
      resourceShow: false, //楼幢房号弹窗开关
      resourceResult: [], //所选房号列表
      selectedResource: [], //所选房号列表id
      myBox: null,

      // --------------------------------基础条款------------------------
      quantity: 0, //租赁数量
      signDate: new Date(), //合同签订时间

      effDateShow: false, //合同开始
      effDate: new Date(), //合同开始时间

      ht_startDate: new Date(), // 合同开始最小时间
      ht_endDate: new Date(), //合同结束最小时间
      expDateShow: false, //合同结束
      expDate: "", //合同结束时间

      depositUnitPrice: null, //租房押金
      depositUnit: 1, //租赁押金单位 1-月，2-元
      propertyDepositUnitPrice: null, //物业押金
      propertyDepositUnit: 1, //物业押金单位

      // --------------------------------租赁条款------------------------
      //-----------------租期条款------------------------
      zuqiList: {
        startTime: null, //合同开始时间
        endTime: null, //合同结束时间
        yearDays: 365, //每年天数
        exacctId: 1, //类型
        calcTimeUnit: 1, //计费类型
        payCycle: 6, //付款周期
        unitPrice: null, //基础单价
        pricingUnit: 1, //基础单价单位
        advancePaytime: 30, //提前还款时间
        advancePaytimeUnit: 2, //提前还款时间单位自然日
        payCycleFlag: true,
        payCycleNewArray: [],
      },
      // --------------物业费条款----------------------
      wuyeList: {
        startTime: null, //合同开始时间
        endTime: null, //合同结束时间
        yearDays: 365, //每年天数
        exacctId: 5, //类型
        calcTimeUnit: 2, //计费类型
        payCycle: 6, //付款周期
        unitPrice: null, //基础单价
        pricingUnit: 2, //基础单价单位
        advancePaytime: 30, //提前还款时间
        advancePaytimeUnit: 2, //提前还款时间单位自然日
        payCycleFlag: true,
        payCycleNewArray: [],
      },
      //-----------自定义付款时间轴
      payCycleNewArray: [],
      /* 租期递增率 */
      dizengChecked: false, //租期递增显示开关
      contractIncreaseVoList: [], //租期递增
      dizengMinDate: new Date(),
      dizengShow: false,
      dizengList: {
        exacctId: 1,
        increaseDate: null,
        increment: null,
        incrementUnit: 1,
      },
      /* 免租期 */
      mianzuChecked: false, //免租期开关
      contractDiscountVoList: [],
      minDate: new Date(), //免租开始最早时间
      maxDate: new Date(), //免租结束最晚时间
      mianzuShow: false,
      mianzuList: {
        startDate: "",
        endDate: "",
        exacctId: 1,
        discountType: 1,
      },

      /* 自定义周期 */
      diyPayCycle: "",
      diyPayCycleArr: "",
      cycleShow: false,
      cycleStartDate: "", //开始时间
      cycleEndDate: "", //结束时间
      cycleDateShow: false,
      cycleMinDate: new Date(),
      cycleMaxDate: new Date(),
      selectCycleDate: "", //选中的事件
      trueIndex: "", //所选时间行
    };
  },
  created() {
    if (this.$route.query.clueId) {
      this.clueId = this.$route.query.clueId;
    }
    if (this.$route.query.infoData) {
      let data = JSON.parse(this.$route.query.infoData);
      console.log(data, "infoData--------------");

      this.tenantId = data.tenantId; //客户id
      this.tenantName = data.tenantName; //客户名
      this.companyName = data.tenantName; //企业名
      this.signUserId = data.signUserId; //联系人id
      this.signUserName = data.tenantUserName; //联系人名
      this.signUserMobile = data.tenantUserMobile; //联系电话
      this.industryId = data.industryId; //行业id
      this.industryName = data.industryName; //行业名
      this.customerManagerId = data.consultantUserId; //客户经理id
      this.customerManagerIdName = data.consultantName; //客户经理
      this.deptId = data.deptId;
      this.deptName = data.deptName;
      this.projectId = data.projectId; //园区id
      this.projectName = data.projectName; //园区
    }
  },
  mounted() {
    if (this.$route.query.editData) {
      this.returnDetail();
    } else {
      this.newContractEdit(this.clueId); //回显信息（新）
    }
    this.getProject(); //获取园区下拉列表
    this.minSelectDate();
    this.projectSelect(this.projectId);
  },
  watch: {
    // 选中房号监听
    resourceResult(val) {
      // 房号面积
      let n = (val) => {
        let res = 0;
        for (let i of val) {
          res += i.area;
        }
        return res;
      };
      this.quantity = n(val);
      // 房号id
      this.selectedResource = [];
      for (let i of val) {
        this.selectedResource.push(i.resourceId);
      }
    },
    //同步租赁条款和物业费条款开始时间
    effDateFilter(val) {
      this.zuqiList.startTime = val;
      this.wuyeList.startTime = val;
      let date1 = new Date(val);
      let date2 = new Date(date1);
      date2.setFullYear(date2.getFullYear() + 1);
      date2.setDate(date2.getDate());
      this.dizengList.increaseDate = this.formatDate(date2);
      let year = date1.getFullYear();
      let month = date1.getMonth();
      let day = date1.getDate();
      this.minDate = new Date(year, month, day);
      this.cycleMinDate = this.minDate;
      this.dizengMinDate = this.minDate;
    },
    //同步租赁条款和物业费条款结束时间
    expDateFilter(val) {
      this.zuqiList.endTime = val;
      this.wuyeList.endTime = val;
      let date1 = new Date(val);
      let year = date1.getFullYear();
      let month = date1.getMonth();
      let day = date1.getDate();
      this.maxDate = new Date(year, month, day);
      this.cycleMaxDate = this.maxDate;
    },
    //监听租金递增开关
    dizengChecked(val) {
      if (val == false) {
        this.dizengList.increment = "";
      }
    },
    //监听免租开关
    mianzuChecked(val) {
      if (val == false) {
        this.contractDiscountVoList = [];
      }
    },
  },
  computed: {
    // 房源类型筛选
    resourcelistFilter() {
      if (this.resourceType == 1) {
        return this.resourcelist.filter((i) => i.resourceType == 1);
      } else if (this.resourceType == 2) {
        return this.resourcelist.filter((i) => i.resourceType == 2);
      } else if (this.resourceType == 3) {
        return this.resourcelist.filter(
          (i) =>
            i.resourceType == 3 || i.resourceType == 4 || i.resourceType == 5
        );
      } else if (this.resourceType == 4) {
        return this.resourcelist.filter((i) => i.resourceType == 6);
      } else {
        return [];
      }
    },
    //合同签订日期
    dateFilter() {
      return this.formatDate(this.signDate);
    },
    //合同开始时间
    effDateFilter() {
      return this.formatDate(this.effDate);
    },
    //合同结束时间
    expDateFilter() {
      return this.formatDate(this.expDate);
    },
  },
  methods: {
    //新建合同回显
    returnDetail() {
      if (this.$route.query.editData) {
        let data = JSON.parse(this.$route.query.editData);
        console.log("data: ", data);
        this.contractId = data.contractId; //合同id
        this.tenantId = data.tenantId; //客户id
        this.tenantName = data.tenantName; //客户名
        this.companyName = data.companyName; //企业名
        this.clueId = data.clueId;
        this.newContractEdit(this.clueId);
        this.industryId = data.industryId; //行业id
        this.industryName = data.industryName; //行业名
        this.legalPerson = data.legalPerson; //法人
        this.contractCode = data.contractCode; //合同编码
        this.projectId = data.resourceDtos[0].projectId; //园区id
        this.projectName = data.resourceDtos[0].projectName; //园区名称
        this.quantity = data.quantity; //面积
        this.signDate = new Date(this.formatDate(data.signDate)); //合同签订时间
        this.effDate = new Date(this.formatDate(data.effDate)); //合同开始时间
        this.expDate = new Date(this.formatDate(data.expDate)); //合同结束时间
        this.depositUnitPrice = data.depositUnitPrice; //租房押金
        this.depositUnit = data.depositUnit; //租房押金类型
        this.propertyDepositUnitPrice = data.propertyDepositUnitPrice; //物业押金
        this.propertyDepositUnit = data.propertyDepositUnit; //物业押金类型
        data.contractClauseVoList.forEach((item) => {
          if (item.exacctId === 1) {
            this.zuqiList.payCycle = item.payCycle; //计费周期  ----------------租赁条款
            this.zuqiList.advancePaytime = item.advancePaytime; //提前还款时间
            if (item.calcTimeUnit === "按月计") {
              this.zuqiList.calcTimeUnit = 2; //计费类型
            } else {
              this.zuqiList.calcTimeUnit = 1;
            }
            this.zuqiList.unitPrice = Number(item.unitPrice); //基础单价
            this.zuqiList.pricingUnit = item.pricingUnit; //基础单价类型
          }
          if (item.exacctId === 5) {
            this.wuyeList.payCycle = item.payCycle;
            this.wuyeList.advancePaytime = item.advancePaytime; //提前还款时间
            this.wuyeList.calcTimeUnit; //计费类型 -----------------物业条款
            this.wuyeList.unitPrice = item.unitPrice; //基础单价
            this.wuyeList.pricingUnit = item.pricingUnit; //基础单价类型
          }
        });
        this.changePayCycle();

        //租金递增率
        if (data.contractIncreaseVoList) {
          this.dizengChecked = true;
          data.contractIncreaseVoList.forEach((item) => {
            if (item.incrementUnit === "元") {
              item.incrementUnit = 2;
            } else {
              item.incrementUnit = 1;
            }
          });
          this.dizengList.increment =
            data.contractIncreaseVoList[
              data.contractIncreaseVoList.length - 1
            ].increment;
          this.dizengMinDate = new Date(
            this.formatDate(
              new Date(
                data.contractIncreaseVoList[
                  data.contractIncreaseVoList.length - 1
                ].increaseDate
              ).getTime() +
                24 * 3600 * 1000
            )
          );
          this.contractIncreaseVoList = data.contractIncreaseVoList;
        } else {
          this.dizengChecked = false;
          this.contractIncreaseVoList = [];
        }
        //租金免租期
        if (data.contractDiscountVoList) {
          this.mianzuChecked = true;
          this.contractDiscountVoList = data.contractDiscountVoList;
          this.minDate = new Date(
            this.formatDate(
              new Date(
                data.contractDiscountVoList[
                  data.contractDiscountVoList.length - 1
                ].endDate
              ).getTime() +
                24 * 3600 * 1000
            )
          );
        } else {
          this.mianzuChecked = false;
          this.contractDiscountVoList = [];
        }
        this.remark = data.remark; //备注
        this.projectSelect(this.projectId);
        this.resourceResult = data.resourceDtos; //所选房源
        detail({ clueId: this.clueId }).then((res) => {
          if (res.success) {
            this.signUserId = res.data.signUserId; //联系人id
            this.signUserName = res.data.tenantUserName; //联系人名
            this.signUserMobile = res.data.tenantUserMobile; //联系电话
            this.deptId = res.data.deptId; //部门id
            this.consultantUserList(this.deptId);
            this.deptName = res.data.deptName; //部门名称
            this.customerManagerId = res.data.consultantUserId; //客户经理id
            this.customerManagerIdName = res.data.consultantName; //客户经理
          } else {
            Toast(res.msg);
          }
        });
      }
    },
    //合同时间最小可选值
    minSelectDate() {
      let time = new Date().getTime() - 30 * 24 * 3600 * 1000;
      let year = new Date(time).getFullYear();
      let month = new Date(time).getMonth();
      let day = new Date(time).getDate();
      this.ht_startDate = new Date(year, month, day);
    },
    // 金额格式
    checkInput(obj, val) {
      obj.value = obj.value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      obj.value = obj.value.replace(/^\./g, ""); //验证第一个字符是数字
      obj.value = obj.value.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      obj.value = obj.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
    },
    // 时间戳转日期格式简易版
    formatDate: function (val, fmt) {
      if (val == null || val == "") {
        return null;
      }
      let date = new Date(val);
      if (!fmt) {
        fmt = "yyyy-MM-dd";
      }
      let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    // 老接口回显信息==============客户信息==========
    clueEdit() {
      let _this = this;
      _this.loadingShow = true;
      clueEdit(this.clueId).then(function (res) {
        _this.loadingShow = false;
        if (res.success) {
          _this.tenantId = res.data.tenantId; //客户id
          _this.tenantName = res.data.tenantName; //客户名
          _this.signUserId = res.data.tenantUserId; //联系人id
          _this.signUserName = res.data.tenantUserName; //联系人名
          _this.signUserMobile = res.data.tenantUserMobile; //联系电话
          _this.industryId = res.data.industryId; //行业id
          _this.industryName = res.data.industryName; //行业名
        } else {
          Toast(res.msg);
        }
      });
    },
    // 部门列表@请求==============部门===============
    newContractEdit(clueId) {
      let _this = this;
      _this.loadingShow = true;
      newContractEdit(clueId).then(function (res) {
        _this.loadingShow = false;
        _this.token = res.token; //令牌
        _this.contractAccess = res.contractAccess; //合同类型
        _this.contractType = res.contractAccess; //合同类型
        _this.deptList = res.deptDtos; //部门@下拉列表
      });
    },
    // 部门弹框开启
    deptShowEven() {
      this.deptShow = true;
    },
    // 选择部门
    deptConfirm(val) {
      this.deptShow = false;
      this.deptId = val.deptId;
      this.deptName = val.deptName;
      this.consultantUserList(val.deptId);
      this.customerManagerId = null;
      this.customerManagerIdName = "";
    },
    // 客户经理列表@请求===========客户经理===========
    consultantUserList(val) {
      let _this = this;
      _this.loadingShow = true;
      consultantUserList().then(function (res) {
        _this.loadingShow = false;
        if (res.success) {
          let data = res.data.filter((i) => i.deptId == val);
          if (data.length > 0) {
            let list = [];
            for (let i of data) {
              list = list.concat(i.empDtos);
            }
            for (let i in data) {
              list[i].deptId = data[i].deptId;
            }
            _this.customerList = list;
          } else {
            Toast("该部门下未查询到客户经理");
            _this.customerList = ["暂无数据"];
          }
        }
      });
    },
    // 客户经理@弹框开启
    customerShowEven() {
      if (this.deptId != null) {
        this.customerShow = true;
      } else {
        Toast("请先选择部门");
      }
    },
    // 客户经理@选择
    customerConfirm(val) {
      this.customerShow = false;
      this.customerManagerId = val.empId;
      this.customerManagerIdName = val.empName;
    },
    // 园区@获取列表===============园区==============
    getProject() {
      let _this = this;
      _this.loadingShow = true;
      getProject().then(function (res) {
        _this.loadingShow = false;
        if (res.success) {
          _this.projectList = res.data;
        } else {
          Toast(res.msg);
          _this.projectList = ["园区列表获取失败,请刷新页面重新下载"];
        }
      });
    },
    // 选择园区
    projectShowEven() {
      this.projectShow = true;
    },
    projectConfirm(val) {
      this.projectShow = false;
      this.projectId = val.projectId;
      this.projectName = val.projectName;
      this.projectSelect(val.projectId); //获取房源
      this.resourceType = 1; //下拉重置
      this.resourceTypeName = "办公室"; //下拉重置
      this.resourceResult = []; //下拉重置
    },
    //获取房号列表==================房号==============
    projectSelect(projectId) {
      console.log(projectId, "projectId-----------------");
      let _this = this;
      _this.loadingShow = true;
      projectSelect(projectId).then(function (res) {
        _this.loadingShow = false;
        console.log(res, "获取房号列表----------------");
        if (res.success) {
          if (res.list.length > 0) {
            _this.resourcelist = res.list;
            _this.resourceArr = res.list;
            let tempArr = [];
            if (_this.resourceResult.length) {
              _this.resourcelist.forEach((item) => {
                _this.resourceResult.forEach((iten) => {
                  if (iten.resourceId === item.resourceId) {
                    tempArr.push(item);
                  }
                });
              });
              _this.resourceResult = [].concat(tempArr);
            }
          } else {
            Toast("该园区下未查询到房源信息");
            _this.resourcelist = res.list;
          }
        } else {
          Toast(res.msg);
        }
      });
    },
    //房源搜索
    onSearch() {
      this.resourcelist = this.resourceArr;
      let arr2 = [];
      let reg = new RegExp(this.searchValue);
      if (this.searchValue != "") {
        for (let i = 0; i < this.resourcelist.length; i++) {
          if (this.resourcelist[i].resourceName.match(reg)) {
            arr2.push(this.resourcelist[i]);
          }
        }
        this.resourcelist = arr2;
      }
    },
    // 选择房源类型
    resourceTypeShowEven() {
      if (this.projectId != null) {
        this.resourceTypeShow = true;
      } else {
        Toast("请先选择园区");
      }
    },
    resourceTypeConfirm(val) {
      this.resourceTypeShow = false;
      this.resourceType = val.resourceType;
      this.resourceTypeName = val.resourceTypeName;
      this.resourceResult = []; //下拉重置
    },
    // 房号弹窗开关
    resourceShowEven() {
      this.searchValue = "";
      if (this.resourceType != null) {
        this.resourceShow = true;
      } else {
        Toast("请先选择房源类型");
      }
    },
    // 选中房号在html组件中自动监听resourceResult
    // 删除房号
    closeresource(item) {
      let index = this.resourceResult.findIndex(
        (i) => i.resourceId == item.resourceId
      );
      this.resourceResult.splice(index, 1);
    },
    // 免租期选择=====================免租期===========
    mianzuConfirm(val) {
      this.mianzuShow = false;
      this.mianzuList.startDate = this.formatDate(val[0]);
      this.mianzuList.endDate = this.formatDate(val[1]);
      this.contractDiscountVoList.push(
        JSON.parse(JSON.stringify(this.mianzuList))
      );
    },
    // 递增时间选择
    dizengDateConfirm(val) {
      if (this.dizengList.increment) {
        this.dizengShow = false;
        this.dizengList.increaseDate = this.formatDate(val);
        this.dizengMinDate = new Date(
          this.formatDate(val.getTime() + 24 * 3600 * 1000)
        );
        (this.dizengList = {
          exacctId: 1,
          increaseDate: this.formatDate(val),
          increment: this.dizengList.increment,
          incrementUnit: this.dizengList.incrementUnit,
        }),
          this.contractIncreaseVoList.push(
            JSON.parse(JSON.stringify(this.dizengList))
          );
      } else {
        Toast("请输入递增率");
        this.dizengShow = false;
      }
    },
    // 关闭免租期展示
    closemianzu(item, index) {
      this.contractDiscountVoList.splice(index, 1);
    },
    // 关闭递增时间展示
    closedizeng(item, index) {
      this.contractIncreaseVoList.splice(index, 1);
      if (this.contractIncreaseVoList.length > 0) {
        let date =
          new Date(
            this.contractIncreaseVoList[
              this.contractIncreaseVoList.length - 1
            ].increaseDate
          ).getTime() +
          24 * 3600 * 1000;
        this.dizengMinDate = new Date(this.formatDate(date));
      } else {
        this.dizengMinDate = this.minDate;
      }
    },
    // 生成费用明细
    getnewCalculateFee() {
      if (this.companyName === "") {
        Toast("请输入企业名称");
        return;
      }
      if (this.deptId == null) {
        Toast("请选择部门");
        return;
      }
      if (this.customerManagerId == null) {
        Toast("请选择客户经理");
        return;
      }
      if (this.projectId == null) {
        Toast("请选择园区");
        return;
      }
      if (this.resourceType == null) {
        Toast("请选择房源类型");
        return;
      }
      if (this.selectedResource.length == 0) {
        Toast("请选择房源房号");
        return;
      }

      if (this.effDate == null) {
        Toast("请选择合同开始日期");
        return;
      }
      if (this.expDate == "") {
        Toast("请选择合同结束日期");
        return;
      }
      if (this.depositUnitPrice == null || this.depositUnitPrice == "") {
        Toast("请填写租房押金");
        return;
      }
      if ((this.depositUnitPrice * 100).toFixed(2) % 1 != 0) {
        Toast("租房押金保留两位小数");
        return;
      }
      if (this.depositUnitPrice < 0) {
        Toast("租房押金金额需大于0");
        return;
      }
      if (
        this.propertyDepositUnitPrice == null ||
        this.propertyDepositUnitPrice == ""
      ) {
        Toast("请填写物业押金");
        return;
      }
      if ((this.propertyDepositUnitPrice * 100).toFixed(2) % 1 != 0) {
        Toast("物业押金保留两位小数");
        return;
      }
      if (this.propertyDepositUnitPrice < 0) {
        Toast("物业押金金额需大于0");
        return;
      }
      this.wuyeList.payCycle = this.zuqiList.payCycle;
      if (this.zuqiList.unitPrice == null || this.zuqiList.unitPrice == "") {
        Toast("请填写租赁基础单价");
        return;
      }
      if ((this.zuqiList.unitPrice * 100).toFixed(2) % 1 != 0) {
        Toast("租赁基础单价保留两位小数");
        return;
      }
      if (this.zuqiList.unitPrice < 0) {
        Toast("租赁基础单价金额需大于0");
        return;
      }
      if (this.dizengList.increment < 0) {
        Toast("租金年递增率不得小于0");
        return;
      }
      if (this.wuyeList.unitPrice == null || this.wuyeList.unitPrice == "") {
        Toast("请填写物业基础单价");
        return;
      }
      if ((this.wuyeList.unitPrice * 100).toFixed(2) % 1 != 0) {
        Toast("物业基础单价保留两位小数");
        return;
      }
      if (this.wuyeList.unitPrice < 0) {
        Toast("物业基础单价金额需大于0");
        return;
      }

      // myBox
      this.zuqiList.startTime = this.effDateFilter;
      this.wuyeList.startTime = this.effDateFilter;
      this.zuqiList.endTime = this.expDateFilter;
      this.wuyeList.endTime = this.expDateFilter;
      this.zuqiList.payCycleNewArray = this.payCycleNewArray;
      this.wuyeList.payCycleNewArray = this.payCycleNewArray;
      let subdata = {
        contractInfoVo: {
          contractId: this.contractId,
          mobileOrPc: true,
          token: this.token, //令牌
          clueId: this.clueId, //线索id
          contractAccess: this.contractAccess, //合同类型
          contractType: this.contractType, //合同类型
          tenantId: this.tenantId, //客户id
          tenantName: this.tenantName, //客户名
          signTenantId: this.tenantId, //客户id
          signTenantUserName: this.tenantName, //客户名
          signUserId: this.signUserId, //联系人id
          signUserName: this.signUserName, //联系人名
          signUserMobile: this.signUserMobile, //联系电话
          industryId: this.industryId, //行业id
          industryName: this.industryName, //行业名
          legalPerson: this.legalPerson, //法人
          contractCode: this.contractCode, //合同编码
          companyName: this.companyName, //合同编码
          deptId: this.deptId, //部门id
          deptName: this.deptName, //部门名
          customerManagerId: this.customerManagerId, //客户经理id
          customerManagerIdName: this.customerManagerIdName, //客户经理名
          projectId: this.projectId, //园区id
          projectName: this.projectName, //园区名
          resourceType: this.resourceType, //房源类型id
          resourceTypeName: this.resourceTypeName, //房源类型名
          selectedResource: this.selectedResource.toString(), //所选房源id
          myBox: this.selectedResource.slice(-1).toString(), //所选房源id最后值
          quantity: this.quantity, //所选房源面积和
          quantityUnit: "1", //所选房源面积和
          signDate: this.dateFilter, //合同签订日期
          effDate: this.effDateFilter, //合同开始日期
          expDate: this.expDateFilter, //合同结束日期
          depositUnitPrice: this.depositUnitPrice, //租房押金
          depositUnit: this.depositUnit, //租房押金单位
          propertyDepositUnitPrice: this.propertyDepositUnitPrice, //物业押金
          propertyDepositUnit: this.propertyDepositUnit, //物业押金单位
          calcPrecision: this.calcPrecision, //计算精度
          unitPricePrecision: this.unitPricePrecision, //保留小数位
          remark: this.remark, //备注
          contractClauseVoList: [].concat(this.zuqiList, this.wuyeList), //租期条款
          contractDiscountVoList: this.contractDiscountVoList,
        },
      };
      // 租期递增
      if (this.dizengChecked) {
        subdata.contractInfoVo.contractIncreaseVoList =
          this.contractIncreaseVoList;
      }
      // 请求费用明细
      let _this = this;
      _this.loadingShow = true;
      newCalculateFee(subdata).then(function (res) {
        console.log(res, "请求费用明细-------------");
        _this.loadingShow = false;
        if (res.success) {
          _this.detailsShow = true;
          _this.contractFeeInfoBoList = [];
          _this.costInfoData = res.contrectFeeMap;
          let data = res.contractFeeBos;
          for (let i of data) {
            for (let j of i) {
              j.cycleEnd = _this.formatDate(j.cycleEnd);
              j.cycleStart = _this.formatDate(j.cycleStart);
              j.paymentDay = _this.formatDate(j.paymentDay);
              j.sectionEnd = _this.formatDate(j.sectionEnd);
              j.sectionStart = _this.formatDate(j.sectionStart);
              if (j.showLevel === 1) {
                j.remark = "本期总计";
              }
              _this.contractFeeInfoBoList.push(j);
            }
          }
          subdata.contractInfoVo.contractFeeInfoBoList =
            _this.contractFeeInfoBoList;
          _this.submitData = subdata;
          console.log(subdata, "subdata--");
        } else {
          Toast(res.msg);
        }
      });
    },
    // 保存
    saveData() {
      let _this = this;
      if (_this.submitData == null) {
        Toast("请生成费用明细在操作");
        return;
      }
      _this.submitData.contractInfoVo.remark = _this.remark;
      if (_this.submitData != null) {
        _this.loadingShow = true;
        if (this.$route.query.editData) {
          newSave2(_this.submitData).then(function (res) {
            _this.loadingShow = false;
            if (res.success) {
              Toast("保存成功");
              _this.businessId = res.data;
              setTimeout(function () {
                _this.approvalShow = true;
              }, 1000);
            } else {
              Toast(res.msg);
            }
          });
        } else {
          newSave(_this.submitData).then(function (res) {
            _this.loadingShow = false;
            if (res.success) {
              Toast("保存成功");
              _this.businessId = res.data;
              setTimeout(function () {
                _this.approvalShow = true;
              }, 1000);
            } else {
              Toast(res.msg);
            }
          });
        }
      } else {
        Toast("请先生成费用明细");
      }
    },
    //监听自定义计费周期
    changePayCycle() {
      let time = this.effDate.getTime() + 24 * 3600 * 1000;
      let year = new Date(time).getFullYear();
      let month = new Date(time).getMonth();
      let day = new Date(time).getDate();
      this.ht_endDate = new Date(year, month, day);
      if (this.effDateFilter && this.expDateFilter && this.zuqiList.payCycle) {
        if (this.zuqiList.payCycle >= 1 && this.zuqiList.payCycle <= 12) {
          this.wuyeList.payCycle = this.zuqiList.payCycle;
          let params = {
            startDate: this.effDateFilter,
            endDate: this.expDateFilter,
            payCycle: this.zuqiList.payCycle,
          };
          getDiyPayCycle(params).then((res) => {
            if (res.success === true) {
              this.diyPayCycleArr = res.data;
              this.payCycleNewArray = JSON.parse(
                JSON.stringify(this.diyPayCycleArr)
              );
              this.payCycleNewArray.forEach((item) => {
                item.startDate = new Date(item.startDate).getTime();
                item.endDate = new Date(item.endDate).getTime();
              });
              this.diyPayCycle = "";
              this.diyPayCycle =
                this.formatDate(this.diyPayCycleArr[0].startDate) +
                "~" +
                this.formatDate(this.diyPayCycleArr[0].endDate);
            }
          });
        } else {
          Toast("计费周期应在1-12个月之间");
          return;
        }
      }
    },
    onCloseSon() {
      this.approveShow = false;
      this.$router.push("/task/taskList");
    },
    //编辑自定义计费周期
    cycleEdit() {
      let _this = this;
      _this.cycleShow = true;
      _this.cycleStartDate = _this.formatDate(_this.effDate);
      _this.cycleEndDate = _this.formatDate(_this.expDate);
      _this.diyPayCycleArr.forEach((item) => {
        item.startDate = _this.formatDate(item.startDate);
        item.endDate = _this.formatDate(item.endDate);
      });
      let item1 = {
        endDate: _this.formatDate(_this.effDate),
        startDate: _this.formatDate(_this.effDate),
      };
      _this.diyPayCycleArr.unshift(item1);
    },
    //点击获取到当前行
    editCycleDate(index) {
      if (index > 0 && index < this.diyPayCycleArr.length - 1) {
        this.cycleDateShow = true;
        this.trueIndex = index;
        let tempTime = new Date(this.diyPayCycleArr[index - 1].endDate);
        let y = tempTime.getFullYear();
        let m = tempTime.getMonth();
        let d = tempTime.getDate() + 1;
        this.cycleMinDate = new Date(y, m, d);
      }
    },
    cycleDateConfirm(date) {
      this.cycleDateShow = false;
      let tempTime = new Date(
        this.diyPayCycleArr[this.trueIndex].endDate
      ).getTime(); //拿到当前行的时间戳

      this.selectCycleDate = new Date(this.formatDate(date)).getTime(); //选中的时间的时间戳
      let timeDifference = this.selectCycleDate - tempTime; //计算出时间戳的差值
      this.diyPayCycleArr[this.trueIndex].endDate = new Date(
        this.diyPayCycleArr[this.trueIndex].endDate
      ).getTime();
      this.diyPayCycleArr[this.trueIndex].endDate = this.formatDate(
        this.diyPayCycleArr[this.trueIndex].endDate + timeDifference
      );
      for (
        let j = this.trueIndex + 1;
        j < this.diyPayCycleArr.length - 1;
        j++
      ) {
        this.diyPayCycleArr[j].startDate = new Date(
          this.diyPayCycleArr[j].startDate
        ).getTime();
        this.diyPayCycleArr[j].endDate = new Date(
          this.diyPayCycleArr[j].endDate
        ).getTime();
        this.diyPayCycleArr[j].startDate = this.formatDate(
          this.diyPayCycleArr[j].startDate + timeDifference
        );
        this.diyPayCycleArr[j].endDate = this.formatDate(
          this.diyPayCycleArr[j].endDate + timeDifference
        );
      }
      this.diyPayCycleArr[this.diyPayCycleArr.length - 1].startDate = new Date(
        this.diyPayCycleArr[this.diyPayCycleArr.length - 1].startDate
      ).getTime();
      this.diyPayCycleArr[this.diyPayCycleArr.length - 1].startDate =
        this.formatDate(
          this.diyPayCycleArr[this.diyPayCycleArr.length - 1].startDate +
            timeDifference
        );
    },
    returnMenu() {
      this.cycleShow = false;
      this.diyPayCycleArr.shift();
      this.diyPayCycle = "";
      this.diyPayCycle =
        this.formatDate(this.diyPayCycleArr[0].startDate) +
        "~" +
        this.formatDate(this.diyPayCycleArr[0].endDate);
      this.payCycleNewArray = JSON.parse(JSON.stringify(this.diyPayCycleArr));
      this.payCycleNewArray.forEach((item) => {
        item.startDate = new Date(item.startDate).getTime() - 3600000 * 8;
        item.endDate = new Date(item.endDate).getTime() - 3600000 * 8;
      });
    },
  },
};
</script>

<style scoped>
/*  背景 */
#addContractNew {
  background: rgba(0, 0, 0, 0.04);
}
/* 信息盒子 */
#addContractNew .box-item {
  border-top: 10px solid rgba(0, 0, 0, 0.04);
  color: rgba(51, 51, 51, 1);
  background: #fff;
  padding: 0 10px;
}

#addContractNew .pTitle {
  font-size: 15px;
  font-weight: bold;
  padding: 5px 0;
}
.inside-box {
  padding: 0 15px;
}
.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dizengDiv {
  padding-top: 25%;
}
.s0 {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.s1 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  background: #1989fa;
}
.costinfo {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
.costinfo > span {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.s10 {
  flex: 1;
  overflow: auto;
}
.s2 {
  flex: 1;
  display: flex;
  overflow: auto;
}
.s2 > .van-checkbox-group {
  width: 100%;
}
.s2 .van-cell__value {
  display: flex;
  justify-content: flex-end;
}
.s3 {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.s3 > button {
  width: 60%;
}
.r1 .van-cell__value {
  display: flex;
  justify-content: space-around;
}
.r1:nth-last-child(1) {
  border-bottom: 10px white solid;
}
.s2-1 {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payCycleTime {
  overflow-y: auto;
}
.diyPayCycleBtn {
  position: fixed;
  bottom: 5px;
  width: 60%;
  height: 6%;
  left: 20%;
  border-radius: 40px;
}
.dizengText {
  display: flex;
  justify-content: center;
}
</style>