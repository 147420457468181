<template>
  <div class="expense-details">
    <div class="header-info">
      <span>租房押金:￥{{ costInfoData.rentalDeposit }}</span
      ><span>物业押金:￥{{ costInfoData.propertyDeposit }}</span>
    </div>
    <div
      class="main-info"
      v-for="(item, index) in costInfoData.periodNumFeeMapList"
      :key="index"
    >
      <div class="infoitems">
        <li class="s1">
          <span>第{{ item.periodNum }}期&nbsp;({{ item.finalUnitPrice }})</span>
          <span
            >{{ formatDate(item.cycleStart) }} ~
            {{ formatDate(item.cycleEnd) }}</span
          >
        </li>
        <li class="s2">
          <span>租金 : ￥{{ item.periodNumRental }}</span>
          <span>物业费 : ￥{{ item.periodNumProperty }}</span>
          <p v-if="item.increaseList">
            <li class="s2" v-for="(i, index) in item.increaseList" :key="index">
              {{ i.exacctId == 1 ? "租金" : "物业" }}递增点 ( {{
                formatDate(i.increaseDate)
              }} ) :
              {{
                i.incrementUnit == 1 ? i.increment + "%" : "￥" + i.increment
              }}
            </li>
          </p>
        </li>
        <li class="s3">
          <van-collapse v-model="activeNames" border>
            <van-collapse-item title="详情" :name="index" style="padding: 0">
              <li class="t1" v-for="(i, index) in item.feeList" :key="index">
                <span>{{ i.exacctName }}:{{ i.finalAmount }}</span
                ><span
                  >{{ formatDate(i.sectionStart) }}~{{
                    formatDate(i.sectionEnd)
                  }}</span
                >
              </li>
            </van-collapse-item>
          </van-collapse>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpenseDetails",
  props: ["costInfoData"],
  data() {
    return {
      activeNames: ["0"],
    };
  },
  methods: {
    formatDate: function (val, fmt) {
      if (val == null || val == "") {
        return null;
      }
      let date = new Date(val);
      if (!fmt) {
        fmt = "yyyy-MM-dd";
      }
      let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
  },
};
</script>
<style scoped>
.expense-details {
  width: 100%;
  padding: 0 12px;
  background-color: #eee;
  overflow: auto;
}
.expense-details .header-info {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}
.expense-details .infoitems {
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
}
.expense-details .s1 {
  padding: 0 10px;
  background: rgba(37, 137, 255, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}
.expense-details .s2 {
  padding: 0 10px;
  line-height: 38px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}
.expense-details .s3 {
  padding: 0 10px;
  line-height: 28px;
  text-align: center;
  color: rgba(37, 137, 255, 1);
}

.expense-details .t1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #eee;
  line-height: 32px;
}
</style>