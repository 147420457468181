<template>
  <div id="approve">
    <header>审批流程</header>
    <main>
      <!-- 流程选择 -->
      <van-field
        readonly
        required
        clickable
        label="审批流程选择"
        v-model="processName"
        @click="flowShoe = true"
        placeholder="请选择审批流程"
      />
      <!-- 流程列表 -->
      <van-steps
        direction="vertical"
        :active="flowList.length"
        active-color="#1989fa"
      >
        <van-step
          v-if="item.nodeType != 1 && item.nodeType != 6"
          v-for="(item, index) in flowList"
          :key="index"
        >
          <div class="flow-item">
            <!-- 左侧 -->
            <div class="flow-itemL">
              <h3>{{ item.nodeName }}</h3>
              <p v-if="item.assigneeType == 1">发起人自己</p>
              <p
                v-if="
                  item.assigneeType == 2 &&
                  (item.nodeType == 2 || item.nodeType == 3)
                "
              >
                {{ item.spUserDtoSet.length }}人{{
                  item.assigneeMethod == 1
                    ? "会签"
                    : item.nodeType == 2
                    ? "或签"
                    : "办理"
                }}
              </p>
              <p v-if="item.assigneeType == 3">
                {{ item.roleName
                }}{{ item.assigneeMethod == 1 ? "会签" : "或签" }}
              </p>
              <p v-if="item.assigneeType == 4">
                {{ item.assigneeLeaderLevel }}级主管{{
                  item.assigneeMethod == 1 ? "会签" : "或签"
                }}
              </p>
              <p v-if="item.assigneeType == 5 && item.nodeType == 2">
                {{ item.spUserDtoSet == null ? "请选择审核人" : "1人审核" }}
              </p>
              <p v-if="item.assigneeType == 5 && item.nodeType == 3">
                {{ item.spUserDtoSet == null ? "请选择办理人" : "1人办理" }}
              </p>
              <p v-if="item.nodeType == 4">
                抄送{{ item.spUserDtoSet.length }}人
              </p>
            </div>
            <!-- 右侧 -->
            <div class="flow-itemR">
              <!-- 添加按钮 -->
              <div
                class="flowItem"
                v-if="
                  !item.spUserDtoSet &&
                  item.assigneeType == 5 &&
                  (item.nodeType == 2 || item.nodeType == 3)
                "
              >
                <van-icon
                  size="30"
                  name="add-o"
                  color="#1989fa"
                  @click="getEmpList(item, index)"
                  v-if="
                    item.spUserDtoSet == null || item.spUserDtoSet.length < 1
                  "
                />
              </div>
              <!-- 人员列表 -->
              <template v-else>
                <div
                  class="flowItem"
                  v-for="(i, k) in item.spUserDtoSet"
                  :key="k"
                >
                  <van-icon
                    name="clear"
                    class="emp-del-icon"
                    size="16"
                    v-if="item.assigneeType == 5"
                    @click="onEmpDel(item, index)"
                  />
                  <van-image
                    round
                    width="28"
                    height="28"
                    :src="i.headPicUrl ? i.headPicUrl : headImgSrc"
                  />
                  <p>{{ i.fullName }}</p>
                </div>
              </template>
            </div>
          </div>
        </van-step>
      </van-steps>
    </main>
    <footer>
      <van-button type="info" block @click="onSubmit">提交审批</van-button>
    </footer>
    <!-- 弹出菜单 -->
    <van-popup v-model="flowShoe" round position="bottom">
      <van-picker
        show-toolbar
        :columns="approveOpt"
        @cancel="flowShoe = false"
        @confirm="onFlowConfirm"
      />
    </van-popup>
    <!-- 弹出可选人员 -->
    <van-popup
      v-model="empListShow"
      position="right"
      :style="{
        width: '50%',
        height: '100%',
      }"
    >
      <div class="emp-head">选择人员</div>
      <van-radio-group v-if="empList.length">
        <van-cell-group>
          <van-cell
            clickable
            :title="item.candidateUserName"
            @click="onEmpSel(item)"
            v-for="item in empList"
            :key="item.candidateUserId"
          >
            <template #right-icon>
              <van-radio :name="item.candidateUserId" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-empty description="暂无可选人员" v-else />
    </van-popup>
  </div>
</template>

<script>
import {
  getApproveOpt,
  getApproveFlow,
  getEmpList,
  getApproveSubmit,
} from "@/api/approve/approve";
import { Toast } from "vant";
export default {
  props: ["processType", "businessId"],
  data() {
    return {
      headImgSrc: require("../assets/images/myself/head.png"),
      flowShoe: false, //弹出菜单开关
      approveOpt: "", //审批列表选项
      processId: "", //流程id
      processName: "", //流程名称
      flowList: [], //流程列表
      flowIndex: "", //当前选中索引
      empList: "", //可选员工信息
      empListShow: false, //可选员工弹框
      params: {
        businessId: "",
        nodeSelectUserMap: "",
      },
    };
  },
  created() {
    this.params.businessId = this.businessId;
    this.getApproveOpt();
  },
  methods: {
    // 【请求】获取审批流程选项
    getApproveOpt() {
      let data = {
        limit: 100,
        processType: this.processType,
        stop: false,
      };
      getApproveOpt(data).then((res) => {
        if (res.data) {
          for (let i of res.data) {
            i.text = i.processName;
            i.value = i.processId;
          }
          this.approveOpt = res.data.filter((i) => i.processId != 68);
        }
      });
    },

    // 【请求】获取审批流程
    getApproveFlow() {
      let data = {
        businessId: this.businessId, //审批工单ID
        processId: this.processId, //流程ID
      };
      getApproveFlow(data).then((res) => {
        if (res.data) {
          this.flowList = res.data;
        }
      });
    },

    // 【请求】提交审批
    getApproveSubmit() {
      let data = this.params;
      getApproveSubmit(data).then((res) => {
        if (res.success) {
          Toast("提交审批成功");
          this.$emit("success");
        }
      });
    },

    // 【监听】选择审批流程
    onFlowConfirm(value) {
      this.processId = value.processId;
      this.processName = value.processName;
      this.params.processId = value.processId;
      this.getApproveFlow();
      this.flowShoe = false;
    },

    // 【监听】获取人员列表
    getEmpList(item, index) {
      this.empListShow = true;
      this.flowIndex = index;
      this.empList = [];
      let roleId = item.assigneeSelectRole == 1 ? "" : item.assigneeSelectRole;
      let data = {
        kw: "",
        roleId,
      };
      getEmpList(data).then((res) => {
        if (res.data) {
          this.empList = res.data;
        }
      });
    },

    // 【监听】人员选择
    onEmpSel(item) {
      this.flowList[this.flowIndex].spUserDtoSet = [
        {
          userId: item.candidateUserId,
          fullName: item.candidateUserName,
          headPicUrl: item.candidateUserHeadUrl,
        },
      ];
      this.empListShow = false;
    },

    // 【监听】选择人员删除
    onEmpDel(item, index) {
      this.flowList[index].spUserDtoSet = null;
    },

    // 【监听】提交审批
    onSubmit() {
      if (!this.processId) {
        return Toast("请选择审批流程");
      }
      let nodeSelectUserMap = {};
      let emplist = this.flowList.filter((i) => i.assigneeType == 5);
      if (emplist.length) {
        for (let i of emplist) {
          if (i.spUserDtoSet) {
            nodeSelectUserMap[i.nodeId] = i.spUserDtoSet[0].userId;
          } else {
            return Toast("请选择" + i.nodeName);
          }
        }
      }
      this.params.nodeSelectUserMap = nodeSelectUserMap;
      this.getApproveSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#approve {
  display: flex;
  flex-direction: column;
  height: 100%;
  header {
    height: 0.46rem;
    background: $theme-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  main {
    flex: 1;
    padding: 0.15rem;
    background: $bg-color;
    overflow: auto;
    .flow-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flow-itemR {
        display: flex;
      }
      .flowItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0.02rem;
        font-size: 0.12rem;
        position: relative;
        .emp-del-icon {
          position: absolute;
          top: -0.05rem;
          right: -0.05rem;
          z-index: 2;
        }
      }
    }
  }
  footer {
    padding: 0.15rem;
    height: 0.48rem;
  }
  .emp-head {
    height: 0.46rem;
    background: $theme-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>