var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"approve"}},[_c('header',[_vm._v("审批流程")]),_c('main',[_c('van-field',{attrs:{"readonly":"","required":"","clickable":"","label":"审批流程选择","placeholder":"请选择审批流程"},on:{"click":function($event){_vm.flowShoe = true}},model:{value:(_vm.processName),callback:function ($$v) {_vm.processName=$$v},expression:"processName"}}),_c('van-steps',{attrs:{"direction":"vertical","active":_vm.flowList.length,"active-color":"#1989fa"}},_vm._l((_vm.flowList),function(item,index){return (item.nodeType != 1 && item.nodeType != 6)?_c('van-step',{key:index},[_c('div',{staticClass:"flow-item"},[_c('div',{staticClass:"flow-itemL"},[_c('h3',[_vm._v(_vm._s(item.nodeName))]),(item.assigneeType == 1)?_c('p',[_vm._v("发起人自己")]):_vm._e(),(
                item.assigneeType == 2 &&
                (item.nodeType == 2 || item.nodeType == 3)
              )?_c('p',[_vm._v(" "+_vm._s(item.spUserDtoSet.length)+"人"+_vm._s(item.assigneeMethod == 1 ? "会签" : item.nodeType == 2 ? "或签" : "办理")+" ")]):_vm._e(),(item.assigneeType == 3)?_c('p',[_vm._v(" "+_vm._s(item.roleName)+_vm._s(item.assigneeMethod == 1 ? "会签" : "或签")+" ")]):_vm._e(),(item.assigneeType == 4)?_c('p',[_vm._v(" "+_vm._s(item.assigneeLeaderLevel)+"级主管"+_vm._s(item.assigneeMethod == 1 ? "会签" : "或签")+" ")]):_vm._e(),(item.assigneeType == 5 && item.nodeType == 2)?_c('p',[_vm._v(" "+_vm._s(item.spUserDtoSet == null ? "请选择审核人" : "1人审核")+" ")]):_vm._e(),(item.assigneeType == 5 && item.nodeType == 3)?_c('p',[_vm._v(" "+_vm._s(item.spUserDtoSet == null ? "请选择办理人" : "1人办理")+" ")]):_vm._e(),(item.nodeType == 4)?_c('p',[_vm._v(" 抄送"+_vm._s(item.spUserDtoSet.length)+"人 ")]):_vm._e()]),_c('div',{staticClass:"flow-itemR"},[(
                !item.spUserDtoSet &&
                item.assigneeType == 5 &&
                (item.nodeType == 2 || item.nodeType == 3)
              )?_c('div',{staticClass:"flowItem"},[(
                  item.spUserDtoSet == null || item.spUserDtoSet.length < 1
                )?_c('van-icon',{attrs:{"size":"30","name":"add-o","color":"#1989fa"},on:{"click":function($event){return _vm.getEmpList(item, index)}}}):_vm._e()],1):_vm._l((item.spUserDtoSet),function(i,k){return _c('div',{key:k,staticClass:"flowItem"},[(item.assigneeType == 5)?_c('van-icon',{staticClass:"emp-del-icon",attrs:{"name":"clear","size":"16"},on:{"click":function($event){return _vm.onEmpDel(item, index)}}}):_vm._e(),_c('van-image',{attrs:{"round":"","width":"28","height":"28","src":i.headPicUrl ? i.headPicUrl : _vm.headImgSrc}}),_c('p',[_vm._v(_vm._s(i.fullName))])],1)})],2)])]):_vm._e()}),1)],1),_c('footer',[_c('van-button',{attrs:{"type":"info","block":""},on:{"click":_vm.onSubmit}},[_vm._v("提交审批")])],1),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.flowShoe),callback:function ($$v) {_vm.flowShoe=$$v},expression:"flowShoe"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.approveOpt},on:{"cancel":function($event){_vm.flowShoe = false},"confirm":_vm.onFlowConfirm}})],1),_c('van-popup',{style:({
      width: '50%',
      height: '100%',
    }),attrs:{"position":"right"},model:{value:(_vm.empListShow),callback:function ($$v) {_vm.empListShow=$$v},expression:"empListShow"}},[_c('div',{staticClass:"emp-head"},[_vm._v("选择人员")]),(_vm.empList.length)?_c('van-radio-group',[_c('van-cell-group',_vm._l((_vm.empList),function(item){return _c('van-cell',{key:item.candidateUserId,attrs:{"clickable":"","title":item.candidateUserName},on:{"click":function($event){return _vm.onEmpSel(item)}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":item.candidateUserId}})]},proxy:true}],null,true)})}),1)],1):_c('van-empty',{attrs:{"description":"暂无可选人员"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }